.footer {
	display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.footer.footer-dark {
    background-color: rgb(249, 249, 249);;
    color: #999;
}