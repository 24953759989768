.container-home {
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: auto 1fr 50px;
	grid-gap: 0px;
	grid-template-areas:
	    "header"
	    "content"
		"footer";
}

.home-page {
	display: grid;
	grid-gap: 0px;
	grid-template-rows: 1fr auto;
}

.content-wrapper {
	max-width: 900px;
    margin: 0px auto;
}

.home-header{
	grid-template: "header";   
	max-width: 900px;
	margin: auto;
}
.container-home > .content {
	/* grid-template: "content"; */
	/* max-width: 900px; */
	/* justify-self: center; */
}
.footer {
	grid-template: "footer";
}

.text-white {
	color: #FFF;
}

.back-drop {
	width: 100%;
	background-color: var(--brand);
	/* border-bottom: inset 2px rgba(0,0,0, 0.1); */
}

.project-hover.center-contents > .card-content {
	display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.project-hover{
	cursor: pointer;
	box-shadow: 0 1px 3px rgba(0,0,0,.3);
	border-radius: 10px !important;
	/* margin-top: -3px;
    margin-bottom: 3px; */
}
.project-hover:hover{
	cursor: pointer;
	box-shadow: 0 3px 4px rgba(0,0,0,.3);
	/* margin-top: -3px;
    margin-bottom: 3px; */
}
.project-hover:active{
	cursor: pointer;
	box-shadow: 0px 1px 2px #CCC;
	/* margin-top: -3px;
    margin-bottom: 3px; */
}

.hover-pointer:hover{
	cursor: pointer;
}
