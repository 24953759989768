.doughnut-wrapper {
    position: relative;
}

.text-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}