@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400');

:root {
    --content-background: #e7ebee;
    --border-color: #cfdbe2;
	--card-border-color: #f1f1f1;
	--input-color: #dfe1e5;
    --text-color: #515253;
    --text-muted: #6c757d;
    --text-more-muted: #D5D5D5;

	/* brand color */
	--real-brand: #233237;
    --brand: #1B9AAA;
    --accent: #DA7B93;

    /* colors */
    /* https://coolors.co/f8ffe5-06d6a0-1b9aaa-ef476f-ffc43d */

    /* more standard colors */
    --success: #06D6A0;
    --information: #1B9AAA;
    --warning: #FFC43D;
	--danger: #EF476F;
	--neutral: #F8FFE5;
    
	--root-overflow: auto;
    --root-height: auto;
}

.text-warning {
	background-color: var(--warning);
}
.text-error {
	background-color: var(--danger);
}
.text-log {
	background-color: var(--information);
}

/* https://coolors.co/b8bfac-01966e-11606b-af3350-bf932d */
.text-string {color: #01966E;}
.text-integer {color:  #11606B;}
.text-boolean {color: #AF3350;}
.text-number {color: #BF932D;}
.text-object {color: #233237;}
.text-array {color: #233237;}


.project-icon {
	width: 48px;
    height: 48px;
    position: absolute;
    top: -42px;
    /* text-align: center; */
    /* margin: auto; */
    padding: 5px;
    background-color: #FFF;
    border-radius: 50%;
    /* border-right: 2px solid #000; */
    left: calc(50% - 24px);
}

.page-btn {
	border-radius: 10px !important;
    box-shadow: 0px 1px 2px rgba(0,0,0,.4) !important;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

*, :after, :before {
    box-sizing: border-box;
}

.input, .select, .textarea {
	border: 1px solid var(--input-color) !important;
}

input:focus, select:focus, textarea:focus {
	/* outline: var(--accent) auto 5px; */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto','Open Sans','Helvetica',Helvetica,Arial,sans-serif;
  color: var(--text-color);
  overflow: var(--root-overflow);
}

.root {
	overflow: var(--root-overflow);
    height: var(--root-height);
}

a {
	text-decoration: none;
	color: var(--text-color);
}
a.anchor {
	color: var(--information);
}

.container {
	min-height: 100vh;
	margin: 0 auto;
	display: grid;
}

.header {
	grid-area: header;
	z-index: 2;
}

.home-header .searchbox {
    border: 0px;
	background-color: rgba(0,0,0,.1);
	color: #FFF !important;
	font-weight: 400;
	width: 100%;
    margin: 5px 0px;
    border-radius: 2px;
    padding: 10px;
    font-size: 16px;
    font-family: inherit;
    color: var(--text-color);
}

.content {
	grid-area: content;
	display: grid;
    grid-template-rows: auto 1fr;
}

.footer {
	grid-area: footer;
}

.sidebar {
	grid-area: sidebar;
}


.page-loader{
	min-height: 200px;
    font-size: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
}

/* PAGE CONTENT */
.page {
	display: grid;
	grid-gap: 0px;
	grid-template-rows: 1fr auto;
	grid-template-areas:
	    "page"
	    "docs";
}

.page-docs{
	grid-template: "docs";
	height: auto;
	display: grid;
	align-items: end;
}
.page-content {
	grid-template: "page";
}

/* COPY TO TURTLEUI*/

/* ITEM SELECTION */
.m-item-selection>.card-content {
	display: grid;
	grid-gap: 0px;
	grid-template-columns: 320px auto;
}
.m-item-selection .m-item-selection-nav {
	border: none;
    border-right: 1px solid #EEE;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
	border-width: 1px !important;
	display: grid;
	grid-gap: 0px;
	grid-template-rows: auto 44px;
}

.m-item-selection .m-selection-header {
	border-top-left-radius: 10px;
	border-bottom: 2px solid var(--card-border-color);
}
.m-item-selection .m-selection-header .button{
	border-top-left-radius: 10px;
}
.m-item-selection .m-selection-header .button:active:before{
	border-top-left-radius: 10px;
}

.m-item-header {
	background-color: #FFF;
    padding: 15px;
	border-bottom: 2px solid var(--card-border-color);
	border-top-left-radius: 10px;
}

.m-item-header .m-header-title {
    font-size: 12px;
    color: var(--text-more-muted);
	text-transform: uppercase;
	text-align: left;
	font-weight: 600;
	display: flex;
    align-items: center;
}

.m-selection-item {
	padding: 10px 15px;
	border-bottom: 2px solid var(--card-border-color);
}
.m-selection-item.active {
	background-color: var(--card-border-color);
}
.m-selection-item:last-child {
	border-bottom: none;
}
.m-selection-item:hover {
	background-color: var(--card-border-color);
	cursor: pointer;
}

.m-selection-items-empty {
	width: 100%;
    height: calc(100% - 46px);
    justify-content: center;
    align-items: center;
    display: flex;
    color: var(--text-more-muted);
    background-color: #FAFAFA;
}

.m-item-selection .m-selection-footer {
	background-color: #fafafa;
	border-bottom-left-radius: 10px;
	align-items: center;
	display: flex;
	padding: 0px 10px;
	border-top: 2px solid var(--card-border-color);
}

.m-selection-active {
	padding: 15px;
}

/* CARDS */
.m-card {
	border-width: 0px !important;
	box-shadow: 0 1px 3px rgba(0,0,0,.3);
	border-radius: 10px !important;
	background-color: #FFF;
}

.m-card.clear-padding>.card-content {
	padding: 0px;
}

/* TABLES */

.m-table {
	border-width: 0px !important;
	box-shadow: 0 1px 3px rgba(0,0,0,.3);
	border-radius: 10px !important;
	background-color: #FFF;
}
.m-table.noshadow {
	box-shadow: none;
}

.m-table input.search {
	margin: 0px;
	border-radius: 10px;
	border: none !important;
	outline: none !important;
	width: 100%;
	font-size: 17px;
}

.m-table tbody tr {
	transition: margin 500ms ease, box-shadow 200ms ease !important;
}

.m-table tbody tr:hover {
	/* box-shadow: 0 3px 2px rgba(0,0,0,.3); */
	/* border-radius: 10px; */
	background-color: rgb(249, 249, 249)
}

.m-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.m-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.m-table tbody tr:hover td {
	/* border-bottom-color: #FFF; */
}
/* .m-table tbody tr:hover td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.m-table tbody tr:hover td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
} */

.m-table thead tr:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.m-table thead tr:first-child th:first-child {
    border-top-left-radius: 10px;
}

.m-table thead tr:first-child th:last-child {
    border-top-right-radius: 10px;
}

.m-table tfoot tr:first-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.m-table tfoot tr:first-child td:first-child {
    border-bottom-left-radius: 10px;
}

.m-table tfoot tr:first-child td:last-child {
    border-bottom-right-radius: 10px;
}

.m-table>thead>tr {
    background-color: #FFF !important;
    text-transform: uppercase !important;
}

.m-table>thead>tr>th {
    text-align: left !important;
    font-weight: 600 !important;
    padding: 15px !important;
    border-bottom: 2px solid var(--card-border-color) !important;
    color: var(--text-more-muted) !important;
    font-size: 12px !important;
}
.m-th {
	color: var(--text-more-muted) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}

hr {
    border: 1px solid var(--card-border-color);
}

hr.thin {
	border: .5px solid var(--card-border-color);
}

.copy-text {
    display: block;
    width: 0;
    height: 0;
    opacity: 0;
}
/* UTILITY */
.empty-state {
	width: 350px;
    margin: auto;
    display: block;
}

.empty-state-sm {
	width: 170px;
    margin: auto;
    display: block;
}

.project-hover{
	transition: margin 250ms ease, box-shadow 200ms ease !important;
}

.new-project {
	border: 2px dashed var(--text-more-muted) !important;
	background-color: transparent;
	border-radius: 2px;
	cursor: pointer;
	transition: margin 250ms ease, box-shadow 200ms ease;
}

.new-project:hover{
	box-shadow: 0px 1px 3px #CCC;
}

.login-back-drop {
	z-index: -1;
    position: absolute;
    width: 100%;
    height: 50vh;
    background-color: var(--brand);
    border-bottom: inset 2px rgba(0,0,0, 0.1);
    left: 0;
    transform: skewY(-6deg);
    transform-origin: top left;
}
.login-card {
	box-shadow: 0px 2px 3px rgba(0,0,0,.3);
	border-radius: 2px;
}
.login-card .card {
	border: 0px !important;
}
.login-card.center-contents > .card-content {
	display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tile-hover {
	transition: background-color 240ms;
}
.tile-hover:hover {
	cursor: pointer;
	background-color: var(--content-background);
}
.dropdown-width-auto > .dropdown-items {
	width: auto !important;
}

.rotate10deg {
	transform: rotate(-10deg);
}

.circle {
	border-radius: 1000px;
	background-color: #AAA;
	height: 20px;
	width: 20px;
    float: left;
    margin-right: 5px;
}

.pull-left {
	float: left;
}
.pull-right {
	float: right;
}

.tag {
	padding: 5px;
    font-size: 80%;
    border-radius: 2px;
}
.tag-2 {
	background-color: var(--success);
	color: #FFF;
}
.tag-4 {
	background-color: var(--information);
	color: #FFF;
}
.tag-5 {
	background-color: var(--danger);
	color: #FFF;
}

.font-md {
	font-size: 18px;
}
.text-small {
	font-size: 80%;
}
.text-xsmall {
	font-size: 60%;
}
.text-medium {
	font-size: 90%;
}
.text-muted {
	color: var(--text-muted) !important;
}
.text-more-muted {
	color: var(--text-more-muted) !important;
}
.vertical-align {
	display: flex !important;
    align-items: center !important;
}
.flex-1 {
	flex: 1;
}

.center-self {
	align-self: center;
}
.g-recaptcha{
	display: flex;
    align-items: center;
    justify-content: center;
}

.align-items-center{
	display: flex;
    align-items: center;
}

.center {
	display: flex;
    align-items: center;
    justify-content: center;
}
.align-center {
	display: flex;
    justify-content: center;
	width: 100%;
}
.align-right {
	display: flex;
    align-items: right;
    flex-direction: row-reverse;
}
.pull-right {
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.full-width {
	width: 100%;
}
.full-height {
	height: 100%;
}
.clear-padding {
	padding: 0px;
}
.clear-margin-top {
	margin-top: 0px;
}
.clear-margin {
	margin: 0px;
}

.root pre {
	background-color: #f7f7f7;
	border: 2px solid #EAEAEA;
	padding: 15px;
}

/* FLEX */
.flex {
	display: flex;
}
.flex-grow {
	flex-grow: 1;
    flex-basis: 0;
}

/* GRID */
.grid {
	display: grid;
	width: 100%;
	grid-gap: 10px;
	grid-template-columns: repeat(12, 1fr);
}
.no-gap{
	grid-gap: 0px;
}
.grid-11 {
	grid-template-columns: repeat(11, 1fr);
}
.grid-10 {
	grid-template-columns: repeat(10, 1fr);
}
.grid-9 {
	grid-template-columns: repeat(9, 1fr);
}
.grid-8 {
	grid-template-columns: repeat(8, 1fr);
}
.grid-7 {
	grid-template-columns: repeat(7, 1fr);
}
.grid-6 {
	grid-template-columns: repeat(6, 1fr);
}
.grid-5 {
	grid-template-columns: repeat(5, 1fr);
}
.grid-4 {
	grid-template-columns: repeat(4, 1fr);
}
.grid-3 {
	grid-template-columns: repeat(3, 1fr);
}
.grid-2 {
	grid-template-columns: repeat(2, 1fr);
}
.grid-1 {
	grid-template-columns: repeat(1, 1fr);
}

.flex-row {
	flex-direction: row;
}
.flex-col {
	flex-direction: column;
}
div[class^="col-"]{
	display: flex;
}
.col-1 {
	grid-column: span 1;
}
.col-2 {
	grid-column: span 2;
}
.col-3 {
	grid-column: span 3;
}
/* span 1 of 3, offset to column 2 */
.col-3-2 {
	grid-column: span 1/3;
}
.col-4 {
	grid-column: span 4;
}
/* span 2 of 4, offset to column 2 */
.col-2-4 {
	grid-column: span 2/4;
}
.col-2-5 {
	grid-column: span 2/5;
}
.col-3-5 {
	grid-column: span 3/5;
}
.col-5 {
	grid-column: span 5;
}
.col-6 {
	grid-column: span 6;
}
.col-7 {
	grid-column: span 7;
}
.col-8 {
	grid-column: span 8;
}
.col-2-8 {
	grid-column: 2 / 8;
}
.col-3-7 {
	grid-column: 3 / 7;
}
.col-3-9 {
    grid-column: 3 / 9;
}
.col-4-8 {
    grid-column: 4 / 8;
}

.grid-column-end-6{
	grid-column-end: 6;
}

.col-right {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

/* LISTS */

/* TYPOGRAPHY */
strong {
	font-weight: 400;
}

.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
	justify-content: left;
}
.text-right {
	text-align: right;
	justify-content: right;
}
.text-300 {
	font-weight: 300;
}
.text-400 {
	font-weight: 400;
}
.text-600 {
	font-weight: 600;
}

.text-brand {
	color: var(--brand);
}

.text-accent {
	color: var(--accent);
}

.text-success {
	color: var(--success);
}

.text-information {
	color: var(--information);
}

.text-danger {
	color: var(--danger);
}

.background-content {
	background-color: var(--card-border-color) !important;
}

.background-accent {
	background-color: var(--accent);
	color: #FFF;
}

.background-success {
	background-color: var(--success);
	color: #FFF;
}

.background-information {
	background-color: var(--information);
	color: #FFF;
}

.background-danger {
	background-color: var(--danger);
	color: #FFF;
}

/* IMAGES */
.circle-md {
	margin-right: 15px;
    width: 75px;
    height: 75px;
    padding: 2px;
    border-radius: 100px;
    border: 2px solid var(--border-color);
}
.circle-sm {
	margin-right: 15px;
	width: 50px;
	height: 50px;
    padding: 1px;
	border-radius: 100px;
    border: 2px solid var(--border-color);
}

.circle-xs {
	margin-right: 5px;
	width: 32px;
	height: 32px;
    padding: 1px;
	border-radius: 100px;
    border: 1px solid var(--border-color);
}

/**/
.content-header{
	/* background-color: var(--card-border-color); */
	box-shadow: 0px 1px 3px rgba(0,0,0,.3);
	background-color: #FFF;
	z-index: 1;
}

/* CUSTOM NAV STYLES */
.nav {
	padding-bottom: 10px !important;
}
.nav.link-underline a {
    padding-bottom: 11px !important;
}

.navlink-description{
	margin: 5px 0px !important;
    font-size: 13px !important;
    line-height: 16px !important;
}

.code {
	background-color: var(--card-border-color);
	padding: 10px;
	border-radius: 5px;
	border: 1px solid var(--border-color);
}

.code .react-json-view {
	margin-bottom: 10px;
}
.code .react-json-view:last-child {
	margin-bottom: 0px;
}

/* UTILITY */
.padding {
	padding: 15px;
}
.padding-top {
	padding-top: 15px;
}
.padding-top-bit-less {
	padding-top: 10px;
}
.padding-bit-less {
	padding: 10px;
}
.padding-less {
	padding: 5px;
}
.padding-more{
	padding: 30px;
}
.padding-side{
	padding-left: 20px;
	padding-right: 20px;
}
.padding-side-bit-less{
	padding-left: 15px;
	padding-right: 15px;
}
.padding-bottom {
	padding-bottom: 15px;
}
.no-padding {
	padding: 0px !important;
}
.no-margin {
	margin: 0px;
}
.no-margin-side {
	margin-left: 0px;
	margin-right: 0px;
}
.no-padding-side {
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.padding-bottom-even-more{
	padding-bottom: 30px;
}

.m5 {
	margin: 5px;
}
.no-margin-top {
	margin-top: 0px;
}
.no-margin-left {
	margin-left: 0px;
}
.margin-left {
	margin-left: 10px;
}
.margin-right {
	margin-right: 10px;
}
.margin-left-less {
	margin-left: 5px;
}
.margin-right-less {
	margin-right: 5px;
}
.margin-top {
	margin-top: 10px;
}
.margin-bottom {
	margin-bottom: 10px;
}
.margin-left-more {
	margin-left: 25px;
}
.margin-right-more {
	margin-right: 15px;
}
.margin-top-more {
	margin-top: 15px;
}
.margin-bottom-more {
	margin-bottom: 15px;
}
.margin-bottom-even-more {
	margin-bottom: 30px;
}
.margin-top-less {
	margin-top: 5px;
}
.margin-bottom-less {
	margin-bottom: 5px;
}

.margin-bottom-none {
	margin-bottom: 0px;
}

.border-bottom {
	border-bottom: 2px solid var(--card-border-color);
    padding-bottom: 5px;
}

.margin {
	margin-top: 10px;
	margin-bottom: 10px;
}
.margin-vertical-5 {
	margin: 5px 0px;
}
.no-margin-left {
	margin-left: 0px;
}

dl {
	margin: 10px 5px;
	font-size: 15px;
}

dl dt, dl dd {
	display: inline;
}
dl dt {
	font-weight: 400;
}
dl dd {
	margin-left: 20px;
}

/* MONACO-EDITOR */
.react-monaco-editor-container {
	border: 1px solid var(--input-color);
	border-radius: 2px;
	margin-right: -9px;
}
.editor-wrapper .react-monaco-editor-container {
	border-top: 0px;
}
.monaco-editor .margin {
	margin: 0px;
}
.monaco-editor .slider:before {
	background-color: transparent;
	display: none;
}
.editor-wrapper .read-only {
	border: 1px solid var(--input-color);
	border-radius: 2px;
	border-bottom: none;
    margin-bottom: -2px;
}

/* TURTLE-UI OVERRIDES */

/* DROPDOWN */
.dropdown-items {
	pointer-events: none;
}
.dropdown-items.show {
	pointer-events: all;
}

/* BUTTON */
.button.disabled {
	pointer-events: none;
	cursor: not-allowed;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}

/* CARDS */
.card {
	/* border: 1px solid var(--card-border-color); */
}

/* DROPDOWN */
.log-filters .dropdown-items.show {
    margin-top: 5px !important;
}

/**/
.project-content {
	padding: 20px;
	background-color: rgb(249, 249, 249);
}

/* json-schema-builder */

.items-input {
	text-align: right;
	background-color: transparent !important;
	border-color: transparent !important;
}

.property{
}

.property-wrapper {

}

.property-wrapper:first-of-type {
	border-left: none;
}

.objectWrapper {
	padding: 15px;
	margin: 5px 0px 5px 0px;
	border-left: 2px solid #D5D5D5;
}

.objectWrapper.__m-root{
	border: none;
	margin: 0px;
	padding: 0px;
}

.modal-wrapper {
	display: flex;
	justify-content: center;
	width: 98vw;
	position: absolute;
	z-index: 100000;
	left: 0;
	top: 0;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 25px;
}

.x-modal .wrapper {
	background-color: #FFF;
	border-radius: 5px;
	padding: 2px;
}

.x-modal .close-modal {
	float: right;
}

.x-modal .content {
	padding: 18px 15px 15px 15px;
	width: 45vw;
}

.x-modal {
	position: relative;
	display: flex;
}

.backdrop-modal {
	position: fixed;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0,0,0,.5);
	left: 0;
	top: 0;
	overflow-x: hidden;
}

/* phone */
@media (max-width: 599px) {
	div[class^="col-"]{
		grid-column: span 1;
	}
}

/* tablet - portrait */
@media (min-width: 600px) {
	.container {
		width: 100%;
	}

}


/* other */
@media (max-width: 768px) {
	.grid.grid-responsive {
	    grid-template-columns: repeat(1, 1fr) !important;
	}
}

@media (max-width: 900px) {
	.grid.grid-responsive {
	    grid-template-columns: repeat(1, 1fr) !important;
	}
	.hidden-small {
		display: none !important;
	}
	.show-small {
		display: block;
	}
}

/* tablet - landscape */
@media (min-width: 900px) {
	.show-small {
		display: none;
	}
}

/* tablet - desktop */
@media (min-width: 1200px) {

}

@media (max-width: 900px) {
	.default-sidebar{
		display:none;
	}
	.mobile-sidebar {
		background-color: transparent;
	}
	.mobile-sidebar .sidebar-content{
		position: inherit;
		background-color: var(--real-brand);
	}
	/* .sidebar{
		display: block;
		position: absolute;
	} */
	.container-project {
		grid-template-columns: unset;
	}
}