:root{
    --sidebar-color: #f8f9fa;
}

.sidebar {
    background-color: var(--real-brand);
}

.sidebar .title {
    height: 64px;
    background-color: var(--brand);
    border-bottom: inset 2px rgba(0,0,0, 0.1);
}

.sidebar-content {
    position: fixed;
    width: 275px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}

.sidebar-footer .links {
    padding: 20px 0px 0px;
    margin: 0px;
    text-decoration: none;
}
.sidebar-footer .links li a {    
    border-top: 1px solid rgba(255, 255, 255, .06);
    padding: 10px;
    text-align: center;
    color: var(--sidebar-color);
    display: block;
    font-size: 15px;
}

.sidebar .links ul {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
}
.sidebar .links ul li {
}

.sidebar .links ul li a {
    padding: 15px;
    color: var(--sidebar-color);
    display: block;
    font-weight: 400;
    /* text-align: center; */
    transition: 250ms background-color, 250ms box-shadow;
}

.sidebar .links ul li a:hover {
    background-color: rgba(255,255,255,.1);
    box-shadow: inset 4px 0 0 var(--sidebar-color);
}

.sidebar .links ul li a.active {
    background-color: rgba(255,255,255,.1);
    box-shadow: inset 4px 0 0 var(--sidebar-color);
}

.sidebar .nav-title {
    font-weight: 400 !important;
    font-size: 16px;
}

.sidebar .nav-icon {
    padding: 8px;
    border-radius: 100px;
    background-color: var(--sidebar-color);
    color: var(--real-brand);
    height: 36px;
    width: 36px;
}